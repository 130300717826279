import { useParams } from "react-router-dom";
import { detailDatas } from "../helpers/Data";
import { Helmet } from "react-helmet";


const BlogDetail = () => {
  const { id } = useParams();
  const post = detailDatas.find((item) => item.id === parseInt(id));

  return (
    <>
      {" "}
      <Helmet>
        <title>Blog | Esma Aksoy</title>
        <meta name="description" content="Your page description" />
      </Helmet>
      
      <div className="min-h-screen bg-[#EFEFEF] font-sans">{post.content}</div>
    </>
  );
};

export default BlogDetail;
