import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";

import { otherProject } from "../helpers/Data";
import OtherProject from "./OtherProject";

const Slider = () => {
  return (
    <Swiper
      slidesPerView={"auto"}
      centeredSlides={true}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      loop={true}
      modules={[EffectCoverflow, Pagination, Navigation]}
      effect={"coverflow"}
      grabCursor={true}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      breakpoints={{
        640: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 3,
        },
      }}
    >
      {otherProject.map((item, index) => (
        <SwiperSlide key={index}>
          <OtherProject {...item} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
