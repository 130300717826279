import React from 'react'

const Title = ({title}) => {
  return (
    <div className="pt-12 mb-12 flex flex-col items-center justify-center">
    <h1 className=" text-black text-3xl pb-1">{title}</h1>
    <div className="h-[0.15rem] bg-[#D5D5D5] w-[9rem]"></div>
  </div>
  )
}

export default Title
