import { Helmet } from "react-helmet";
import TimeLine from "../components/TimeLine";
import Title from "../components/Title";

const About = () => {
  return (
    <>
      <Helmet>
        <title>About | Esma Aksoy</title>
        <meta name="description" content="Your page description" />
      </Helmet>

      <div className="bg-white overflow-x-hidden">
        <Title title={"ABOUT ME"} />

        <TimeLine />

        <div className="text-black flex flex-col items-end px-10 pb-8 sm:p-20">
          <p className=" tracking-normal mb-3">
            To sing, to laugh, to dream,to walk in my own way and be alone,
            free, with an eye to see things as they are...
          </p>
          <p className="font-whisper text-2xl tracking-normal">
            Cyrano de Bergerac
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
