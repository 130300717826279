import { Helmet } from "react-helmet";
import { PiCodeThin } from "react-icons/pi";
import { GiQuillInk } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import gsap from "gsap";
import { useEffect, useRef } from "react";

const Blog = () => {
  const navigate = useNavigate();
  const literatureRef = useRef();
  const technicalRef = useRef();

  useEffect(() => {
    gsap.fromTo(
      literatureRef.current,
      { y: 100, opacity: 0 },
      { y: 0, duration: 3, opacity: 1 }
    );
    gsap.fromTo(
      technicalRef.current,
      { y: -100, opacity: 0 },
      { y: 0, duration: 3, opacity: 1 }
    );
  }, []);

  
  //     gsap.to(cloudRef.current, {
  //       x: '-100%',
  //       duration: 10,
  //       ease: 'linear',
  //       onComplete: () => {
  //         gsap.set(cloudRef.current, { x: '100%' });
  //         animateCloud();
  //       }
  //     });
  //   };
  //   gsap.to(cloudRef.current, {
  //     y: '+=50', // aşağı doğru hareket
  //     duration: 2.5,
  //     ease: 'sine.inOut',
  //     repeat: -1, // sonsuz tekrar
  //     yoyo: true // hareketi tersine çevirir
  //   });

  //   animateCloud();
  // }, []);
  return (
    <>
      <Helmet>
        <title>Blog | Esma Aksoy</title>
        <meta name="description" content="Your page description" />
      </Helmet>

      <div className="min-h-screen p-5 lg:p-[5rem] flex justify-between cursor-pointer text-center">
        <div
          className="flex flex-col gap-12 items-center justify-center border-l border-t border-white p-5 "
          onClick={() => navigate("/blog/technical")}
          ref={technicalRef}
        >
          <p className="text-white text-3xl sm:text-[3rem] leading-[3rem]">
            My Technical Writings
          </p>
          <PiCodeThin className="text-white text-[5rem] sm:text-[10rem]" />
        </div>
        
        <div
          className="flex flex-col gap-12 items-center justify-center border-r border-t border-black p-5 cursor-pointer"
          onClick={() => navigate("/blog/literature")}
          ref={literatureRef}
        >
          <p className="text-black text-3xl sm:text-[3rem] leading-[3rem] flex">
            My Literary Writings
          </p>
          <GiQuillInk className=" text-[5rem] sm:text-[10rem]" />
        </div>
      </div>
    </>
  );
};

export default Blog;
