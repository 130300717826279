import blog from "../assets/blog.png";
import stock from "../assets/stock.png";
import movie from "../assets/movie.png";
import recipe from "../assets/recipe.png";
import me from "../assets/me.gif";
import { Helmet } from "react-helmet";

import Title from "../components/Title";

import ProjectCard from "../components/ProjectCard";
import { otherProject, projectData } from "../helpers/Data";
import OtherProject from "../components/OtherProject";
import { Link } from "react-router-dom";
import Slider from "../components/Slider";

const Project = () => {
  return (
    <>
      <Helmet>
        <title>Project | Esma Aksoy</title>
        <meta name="description" content="Your page description" />
      </Helmet>

      <div className="min-h-screen bg-white px-3 sm:py-5 sm:px-12">
        <Title title={"My Latest Projects"} />

        <div className="">
          {projectData.map((item, index) => (
            <ProjectCard key={index} {...item} />
          ))}
        </div>

        <div className="bg-[#EDEDED]">
          <Title title={"My Other Projects"} />
          <Slider />
        </div>

        <div className="flex flex-col p-5 text-center">
          <p className="text-xl sm:text-3xl mt-12">
            If you want to see more projects, you can visit my{" "}
            <Link
              to="https://github.com/esmaaksoy"
              className="text-gray-500 hover:text-[#FEE653]"
            >
              GitHub account...
            </Link>
          </p>
          <img src={me} alt="image" className="w-[15rem] m-auto" />
        </div>

        <div className="bg-[#F7F7F7] ">
          <Title title={"My Clients"} />
          <Link to="https://vessino.com/" target="_blank">
            <div className="w-[200px] sm:w-[300px] m-auto pb-10">
              <img
                src="https://vessino.com/assets/Vessino-Header-H-2nupiv.png"
                alt="image"
              />
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Project;
