import man from "../assets/man.jpg";
import bird from "../assets/birdMinimal.jpg";
import cöl from "../assets/cöl.jpg";
import sun from "../assets/sun.jpg";
import book from "../assets/book.jpg";
import city from "../assets/city.jpg";
import hansel from "../assets/hansel.png";
import hansel1 from "../assets/hansel-1.png";
import hansel2 from "../assets/hansel-2.png";

import blog from "../assets/blog.png";
import stock from "../assets/stock.png";
import home from "../assets/home.png";
import movie from "../assets/movie.png";
import recipe from "../assets/recipe.png";
import things from "../assets/things.png";
import bookStore from "../assets/bookStore.png";
import bookTracker from "../assets/bookTracker.png";
import wednesday from "../assets/wednesday.png";
import hogwarts from "../assets/hogwarts.png";
import color from "../assets/color.png";

import image1 from "../assets/Google Search Console/1.png";
import image2 from "../assets/Google Search Console/2.png";
import image3 from "../assets/Google Search Console/3.png";
import image4 from "../assets/Google Search Console/4.png";
import image5 from "../assets/Google Search Console/5.png";
import image6 from "../assets/Google Search Console/6.png";
import image7 from "../assets/Google Search Console/7.png";
import image8 from "../assets/Google Search Console/8.png";
import google from "../assets/Google Search Console/search.jpg";

import germany from "../assets/germany.jpg";
import graduated from "../assets/graduated.jpg";
import experience from "../assets/experience.gif";
import finland from "../assets/finland.jpg";
import chess from "../assets/chess.jpg";
import amigurimi from "../assets/amigurimi.jpg";
import write from "../assets/write.jpg";
import certification from "../assets/certification.jpg";
import me from "../assets/me.gif";

import { PiTranslateThin } from "react-icons/pi";
import { PiTextAlignRightThin } from "react-icons/pi";
import { PiCodeThin } from "react-icons/pi";
import { PiSparkleThin } from "react-icons/pi";
import { PiCertificateThin } from "react-icons/pi";
import { PiSunHorizonThin } from "react-icons/pi";
import { PiCheckThin } from "react-icons/pi";
import { PiGraduationCapThin } from "react-icons/pi";

import { RiLinkedinLine } from "react-icons/ri";
import { PiGithubLogoLight } from "react-icons/pi";
import { FaMedium } from "react-icons/fa6";

import { PiCactusThin } from "react-icons/pi";
import { PiChartLineUpThin } from "react-icons/pi";
import { PiBuildingsThin } from "react-icons/pi";

import { Link } from "react-router-dom";

export const socialData = [
  { icon: <RiLinkedinLine />, href: "https://www.linkedin.com/in/esma-aksoy/" },
  { icon: <PiGithubLogoLight />, href: "https://github.com/esmaaksoy" },
  { icon: <FaMedium />, href: "https://medium.com/@esma.ea.aksoy" },
];

export const detailDatas = [
  {
    id: 1,
    content: (
      <>
        <div className="md:flex p-2 lg:p-10">
          <div className="md:w-[60%] lg:w-[80%] p-5">
            <p className="mb-4">Boşluklu yaşamak, boşluklu yaşamak...</p>
            <p className="mb-2">
              Bu iki kelimeyi duyduğunuzda sizin aklınıza ne geliyor? Günlerdir
              bu iki kelime dolanıp duruyor aklımda. Bir şey aklımı kemirmeye
              başlayınca oturup yazmaya başlarım. Hani şu iki kelimeye dayanıp
              yazıyorum ama nereden geldi şimdi, kimindi bu kelimeler. Bu kadar
              güzel iki kelimeyi yan yana ben mi getirdim acaba, çok da tanıdık
              geliyorlar ama. Tüm bu karmaşa özgünlük kelimesinde çözülüyor
              aslında. Özgünlük, girdiği anlam derinliğinden çekip
              çıkarılamayacak kadar dibe batmış durumda. Orhan Pamuk’un
              şehzadesi özgün olamayacağı endişesiyle okumayı bırakmıştı.Tabii
              okumak sadece kitaplara hassa... İnsan pekala okuduğu bir insanı
              da yansıtabilir ama bunların konumuzla alakası yok, konumuz
              boşluklu yaşamak...
            </p>

            <p className="mb-2">
              Boşluklu yaşamak... O kadar çok şey ifade ediyor ki yaşadıgı çağı
              sorgulayanlara. Tüm bu sorgulamalar altında "özgün veya degil ne
              hissediyorsan artık yazmalısın” dedim kendime. Ertelenmişler
              arasından web sitemi çıkarıp kodlarımı yazmaya başladım...
              Kodlama; yeni nesil edebiyat. İnsan yazdığı her satırda
              başkasından esinlenme tehlikesi altında. Mı acaba? Edebiyatçılar
              böyle düsünüyor, peki ya yazılımcılar? Yazılımda işler böyle
              yürümüyor. Okuyunca anlıyorsan yaz bakalım benim kodumu diyor
              onlar. Gün geliyor insan kendi koduna bile yabancılaşıyor,
              çalıştığı fabrikaya mı yabancılaşmayacak...
            </p>
            <p className="mb-4">
              Bizi biz yapan hikayeler de diyor ki Randell; "ileriye doğru
              yaşıyoruz ama geriye doğru anlıyoruz". Üniversite hayatıma bakınca
              anlıyorum ki web sitelerinde erişilebilirlik ve kullanılabilirlik
              çalıştığımda, beni heyecanlandıran her şey buradaymış, hata mı var
              oo çözülecek bir şeyler varmış burada, her yer çok mu dağılmış
              clean yazılacak kodlar var demekki etrafta… Geç kalmamışımdır da
              yeni varmışımdır diyelim biz ona. Buradasınız diyor şimdi yazdığım
              tüm kodlar. Buradasınız? Aklıma Leyla ile Mecnun’dan şu sahne
              geliyor hemen "tamam buradayım da burası neresi ki ya". İnsan o
              kadar yabancılaşıyor işte kendi koduna. Öyle veya böyle bu kodlar
              yazıldı ve boşluklu yaşamak cümlesinin bir şiir başlığı olduğu
              hatırlandı. Yazıyı bitirmeden sorayım, sizin kodunuz hangi şiirden
              esinleniyor? Benim kodum boşluklu yaşamak şiirinden esinlendi,
              yabancılaşma kuramına dayanıyor, deploy edilmis hali William
              Randell'da anlatılıyor... Temiz kod yazmayı ögrenmem gerektiği
              kadar, anlaşılır yazılar yazmayı da öğrenmeliyim ama yazarken
              işler çok çabuk karışıveriyor..
            </p>
            <div className="w-[80%] lg:w-[30%] ml-auto">
              <h2 className="mb-1">Boşluklu Yaşamak</h2>
              <p className="mb-3 text-center font-medium">...</p>
              <p className="mb-3">
                bana kalsa buna gitmek demezdim / gitmek istememek de demezdim /
                biz buna kabulleniş diyemezdik/ biz bunda direniş de
                aramamalıydık / bu belki bir bağdı / koparılamayan / müşterek /
                oluşumuzun içinde.
              </p>
              <p className="mb-3 text-center font-medium">...</p>
              <p>
                Adamın kafasında koskoca bir güneş var diyorum ben Adamın
                kafasında sultanahmedin güvercinleri Gülhanenin ağaçları
                Oturacak yerleri parkların...
              </p>
              <p className="mb-3 text-center font-medium">...</p>
              <p className="text-end font-whisper text-3xl">Erdem Beyazıt</p>
            </div>
          </div>
          <div className="md:w-[40%] lg:w-[20%]">
            <img src={man} alt="image" />
          </div>
        </div>
      </>
    ),
  },
  {
    id: 2,
    content: (
      <>
        <div className="md:flex p-2 lg:p-10 bg-[#E5E5E5] min-h-screen">
          <div className="md:w-[60%] lg:w-[80%] p-5">
            <p className="mb-4">Kelimeler ve Şeyler</p>
            <p className="mb-2">
              Aynı dili konuşuyoruz ama bazen birbirimizi anlayamıyoruz, çünkü
              aynı sözlüğü kullanmıyoruz. Herkesin kendine has bir sözlüğü var,
              herkesin kelimelere yüklediği farklı anlamlar... Belki sizin de
              bir tarafınız Oğuz Atay’dır ve “kelimeler bazı anlamlara gelmiyor”
              diyordur, ama diğer tarafınız Foucault’dır ve sizin için her şey
              budur işte; kelimeler ve şeyler...
            </p>
            <p className="mb-2">
              Yaşadığım hiçbir şeyin alalede ve gelişigüzel olduğuna inanmadım.
              Hele söz konusu kelimelerse... Hayatımı idame ettirmek için
              yeterli diye bana dayatılan 500 kelimenin dışına çıkmaya
              başladığımda, hayatıma yeni kelimeler katmaya başladıktan sonra,
              her kelimeyle ayrı bir bağım oluşmaya başladı, öyle müşterek ve
              severek... Benim sözlüğüm tarihler ve notlarla dolu, hangi
              kelimenin hangi tarihte hayat hikayeme bağlandığını biliyorum. Ama
              bazılarını bilmekten öte ayrı bir seviyorum.
            </p>
            <p className="mb-4">
              Kelimeler ve şeyler... Bu ikilinin hayatıma ne zaman girdiğini
              hala tatlı bir şekilde anımsıyorum, sonra nasıl hayatımın kendisi
              haline geldiğini de. Öyleydi o zamanlar, tam olarak bu ikili
              hayatımın kendisiydi, kelimeler vardı hayatımda bir de şeyler.
              Kelimeler,Cemil Meriç yalnızlığı çekenlerin sancısı... Beni
              tanıyan herkes için de bir anlamı var artık bu ikilinin... Belki
              Bukowski kadar çaresiz değildim ama en az onun kadar ihtiyacım
              vardı kelimelere, kütüphanede yine kelimelerle avunmaya çalışırken
              tanıştım Foucault ile, böyle incecik, sararmıs ve tozlu bir yığın
              altından bakıyordu bana, adeta bu iki kelimeye fokuslandım, evet
              bu dedim ben de hayata dair derin ve anlamlı bir şey söylemek
              isteseydim tam olarak bunu derdim; kelimeler ve şeyler...
            </p>
            <p className="mb-4">
              Kelimeler ve şeyler... Minimalizm ruhunu her anlamda yansıtan
              muhteşem bir ikili. Kelimenin nadideliğini öne çıkarıp diger her
              şeyi bir kenara ayırmış. Kelimeler var bir de geri kalan şeyler.
              Geri kalan her şey sadece bir şey. Ama öte yandan “şey”
              kelimesinin orjinalliği. Çünkü “şey” de öyle bir şey ki hiçbir şey
              ile açıklanamayan bir şey. Bugüne kadar okuduğum en güzel “şey”
              analizi Bauman’dan geldi. İnsan kitabı açıp açıp tekrar okuyor,
              işte böyle bir şey bu “şey”.
            </p>
            <p className="mb-4">
              Kelimeler ve şeyler bir Erkan Şimsek hayranı olarak hiç
              kaçırmadığım o muhteşem programın da ismi. Bu yazımı ben programın
              tek bir kelimesini kaçırmayayım diye çayımı bile getiren canım
              arkadaşıma armağan ediyorum. Not aldığım peçeteleri hala
              saklıyorum...
            </p>
          </div>
          <div className="md:w-[40%] lg:w-[20%]">
            <img src={bird} alt="image" />
          </div>
        </div>
      </>
    ),
  },
  {
    id: 3,
    content: (
      <div className="lg:p-12">
        {" "}
        <div className="flex bg-[#F4F4F4] p-5 lg:p-12 flex-col lg:flex-row gap-5">
          {" "}
          <img src={cöl} alt="image" className=" mb-5  " />
          <div>
            <h1 className="text-2xl text-center">Tatar Çölü</h1>
            <h1>Neyi bekliyoruz böyle toplanmış pazar yerine?</h1>
            <h1>Bugün barbarlar geliyormuş buraya.</h1>
            <h1 className="text-end pr-12">Kavafis</h1>
            <p>
              {" "}
              Kavafis’in şiirinden beklemek üzerine iki kitap doğdu, şiir ile
              aynı adı taşıyan Coetzee’nin "Barbarları Beklerken" kitabı ve
              Buzzati’nin Tatar çölü. Tatar çölü, beklemek üzerine muhteşem bir
              anlatı... Beklemek de sadece doğal bekleyenlere has bir acı.
              Attığınız her adımda içli bir türkü duyuyorsa kulaklarınız,
              baktığınız her menzilde bekleteni arıyorsa gözleriniz; demekki siz
              de doğal bir bekleyensiniz. Doğal bir bekleyenseniz doğal
              bekletenleri ilk bakışta tanırsınız, o an anlarsınız Bastiani
              kalesidir yeni görev yeriniz, Tatar çölüdür yeni menziliniz, artık
              yolunuz bu çöle meftun... Herkes değişir, dönüşür, yol alır ama
              siz bekleme kulenizde tekrar tekrar aynı güne uyanırsınız.
              Beklerken zaman mefhumunuz şaşar, sabrınız taşar, önce
              dayanırsınız sonra aldanırsınız. Her gün uyandığınız çöle bakıp
              uzak menzilden çıkıp geleceklere aldanırsınız... Gelseler bir
              anlamı olacaktır beklemenin ama ya çıkıp gelmezler ya da Drogo
              gibi düşünce gelirler, ömrünüz biter, yaşama amacınız güme gider,
              yerinize başkaları geçer... İnsan Tatar Çölüne atanıyor, kavafis
              in şiirinde bir mısra oluyor da gönlü hep Madak’ta kalıyor,
              çiçekli şiirler yazmak istiyorsunuz ama kimse bilmiyor darmadağın
              gövdenizi, çiçekli perdelerin arkasında saklıyorsunuz.{" "}
            </p>
          </div>{" "}
        </div>
        <div className="flex p-5 lg:p-12 items-center bg-white flex-col lg:flex-row">
          <div>
            <h1 className="text-2xl text-center mb-5">
              Güneş Topla Benim İçin
            </h1>
            <p>
              Bugün pazar... <br /> Nazım'ı ilk defa güneşe çıkardılar. Ben de
              gökyüzünün bu kadar benden uzak oluşuna şaşarak durdum. Hugo
              Fransa'nın ışık hüzmelerine aldanmadı; "güneşinin görkemli
              olduğuna şüphe yok yine de göğün mavisi, altındaki paçavrayı
              gizlemeye yetmiyor"... Ama Diyojen bir imparatoru azarladı;
              "güneşimin önünden çekil"... Tamer'in kaleminden dökülen
              Livaneli'de melodiye kavuştu "güneş topla benim için"... Birhan
              Keskin buraya küçük mutlu güneşler koydu, kimbilir belki birazdan
              dokunuruz. Koskocaman güneş hepimize dert oldu...Bugün yine pazar,
              biz yine aynı güneşin altında üşümüş kalplerimizi ısıtmaya çalışan
              üç beş arkadaşız sadece, gökyüzünün uzak oluşu hala bizi
              şaşırtıyor, bir imparatoru azarlayacak cesaretimiz yok, ekranda
              sefiller oynuyor. Dokunabilsek şu küçük güneşe talibiz.
              Toplayabildiğimiz tüm güneşler için...
            </p>
          </div>

          <img src={sun} alt="image" className="" />
        </div>
      </div>
    ),
  },
  {
    id: 4,
    content: (
      <div className="py-5 lg:px-[7rem]">
        <h1 className="text-2xl text-center font-semibold">
          Adding Images in React
        </h1>
        <p className="p-5">
          Hansel and Gretel thought they could find their way with the
          breadcrumbs they scattered along the path, but there was one thing
          they didn’t account for—the breadcrumbs had disappeared, and so had
          their path. Sometimes, even if you're sure your path is correct, if
          you lose the breadcrumbs, you may not be able to go back. If you have
          enough imagination, if the child within you who loves to get lost in
          reading is still alive, and if Evgeny Grinko’s Valse is playing in the
          background, don’t be afraid of the images that don’t appear even
          though you’ve correctly written the path while learning React. Fairy
          tales guide us to the right path, even in software. In this article, I
          want to talk about a problem I encountered and the solution I found
          while learning React. As usual, I added my image to my file and
          expected it to appear on my page by specifying the file path, but the
          image never showed up. Despite checking the path multiple times, I
          couldn’t solve the problem, and after some research, I found the
          solution. There are several ways to add images in React. If you’re
          thinking of using the traditional method of creating a local "image"
          folder, putting your images there, and then entering the image path in
          src, you should scatter pebbles along the path instead of breadcrumbs.
        </p>
        <img src={hansel1} alt="image" className="m-auto" />
        {/* <pre className=" bg-black text-white  p-5 text-[13px] w-[90%] m-auto">
          <code> &lt;img src="./image/hansel.jpg" alt="hansel"/&gt;</code>
        </pre> */}
        <p className="p-5">
          When you add your image this way, due to webpack, your images will not
          be visible. Therefore, you should add elements like images with
          import.
        </p>
        <img src={hansel2} alt="image" className="m-auto" />
        {/* <pre className=" bg-black text-white p-5 text-[13px] w-[90%] m-auto">
          <code>
            import hansel from "./image/hansel.jpg";{"\n"}
            &lt;img src=&#123;hansel&#125; alt="hansel"/&gt;
          </code>
        </pre> */}
        <p className="p-5">
          When you import, webpack can correctly position it. This way, you can
          find Hansel. Another point to note is that your image is now a
          variable, so you should write it using the logic of declaring
          variables in React. If you don’t want to deal with any of this, and
          you never liked the Grimm brothers anyway, we have another method for
          adding images. If you place your images in the Public folder, you can
          now enter the path of your image in src. This way, you directly access
          your image. From a usability perspective, if you have 1000 images and
          you want to access all of them, it would be more practical to add them
          to the Public folder instead of importing each one individually.
        </p>
      </div>
    ),
  },
  {
    id: 5,
    content: (
      <div className="p-5 sm:py-12 sm:px-[3rem] lg:px-[7rem]">
        <h1 className="text-3xl sm:text-4xl font-semibold text-center">
          Google Search Console Kullanımı
        </h1>

        <div className="mt-5">
          <p className="mb-2">
            Haftalar süren uğraşlar sonunda web sitenizi geliştirdiniz, deploy
            ettiniz, domain ve hostingi bağladınız. Peki şimdi ne yapacaksınız?
            Artık yaptığınız işlemlerden Google’ı da haberdar edebilirsiniz. Bu
            makalede, web sitenizi Google’a nasıl tanıtacağınızdan ve Google
            Search Console'u nasıl kullanacağınızdan bahsedeceğim.
          </p>
          <p>
            Google Search Console, web sitenizin Google arama sonuçlarındaki
            performansını izlemenize ve optimize etmenize yardımcı olan ücretsiz
            bir hizmettir. Bu araç, sitenizin arama trafiği ve performansı
            hakkında detaylı bilgiler sunar, teknik sorunları tespit eder ve
            düzeltir, sitenizin dizine eklenme durumunu izler ve geliştirir.
          </p>
        </div>

        <div className="mt-5">
          {" "}
          <h2 className="font-semibold text-2xl">
            {" "}
            Google Search Console'a Kayıt ve Doğrulama
          </h2>
          <div className="mt-5">
            {" "}
            <h3 className="text-xl font-semibold mb-3">
              1. Google Search Console'a Giriş
            </h3>
            <p>
              Google Search Console'u kullanabilmek için öncelikle bir Google
              hesabına sahip olmanız gerekir. Ardından Google Search Console
              sitesine giderek giriş yapabilirsiniz. Siteye giriş yaptığınızda
              sizi aşağıdaki gibi bir ekran karşılayacak. Burada “şimdi başla”
              butonuna tıklıyoruz.
            </p>
            <img src={image1} alt="image" className="m-auto mt-5" />
          </div>
          <div className="mt-5">
            {" "}
            <h3 className="text-xl font-semibold mb-3">2. Web Sitesi Ekleme</h3>
            <p>
              Giriş yaptıktan sonra, bizi aşağıdaki gibi bir ekran karşılayacak.
              Mülk doğrulamak için bize iki seçenek sunuyor, bu seçeneklerden
              birisini seçerek web sitenizin URL'sini girin. Google, sitenizin
              sahibi olduğunuzu doğrulamanızı isteyecektir.
            </p>
            <img src={image2} alt="image" className="m-auto mt-5" />
          </div>
          <div className="mt-5">
            <h3 className="text-xl font-semibold mb-3"> 3. Mülk Doğrulama</h3>
            <p>
              Sitenizin sahibi olduğunuzu doğrulamak için birkaç yöntem mevcut:
            </p>
            <ul className="list-disc pl-4">
              <li>
                {" "}
                HTML Dosyası Yükleme: Google tarafından verilen bir HTML
                dosyasını sitenizin kök dizinine yükleyin.
              </li>
              <li>
                HTML Meta Etiketi: Belirtilen meta etiketini sitenizin ana
                sayfasının <code>&lt;head&gt;</code> bölümüne ekleyin.
              </li>
              <li>
                Google Analytics: Eğer sitenizde Google Analytics
                kullanıyorsanız, bu hesabı kullanarak doğrulama yapabilirsiniz.
              </li>
              <li>
                Google Tag Manager: Google Tag Manager kullanarak da doğrulama
                gerçekleştirebilirsiniz.
              </li>
              <li>
                {" "}
                DNS Kayıtları: DNS sağlayıcınıza özel bir TXT kaydı ekleyerek
                doğrulama yapabilirsiniz.
              </li>
            </ul>
            <p>
              Doğrulama işlemi tamamlandıktan sonra, web siteniz Google Search
              Console'a eklenmiş olacaktır. Şimdi sizi aşağıdaki gibi Google
              Search Console’un giriş ekranı karşılayacak.
            </p>
            <img src={image3} alt="image" className="m-auto mt-5" />
          </div>
        </div>

        <div className="mt-5">
          <h2 className="font-semibold text-2xl">
            Google Search Console'un Temel Özellikleri
          </h2>
        </div>

        <div className="mt-5">
          {" "}
          <h3 className="text-xl font-semibold mb-3">1. Performans Raporu</h3>
          <p>
            Performans raporu, sitenizin Google arama sonuçlarındaki
            performansını izlemenizi sağlar. Bu rapor, aşağıdaki metrikleri
            içerir:
          </p>
          <ul className="list-disc pl-4">
            <li>
              Toplam Tıklama Sayısı: Kullanıcıların sitenize tıklama sayısı.
            </li>
            <li>
              Toplam Gösterim Sayısı: Sitenizin arama sonuçlarında kaç kez
              göründüğü.
            </li>
            <li>
              Ortalama Tıklama Oranı (CTR): Gösterimlere göre tıklama oranı.
            </li>
            <li>
              Ortalama Pozisyon: Sitenizin arama sonuçlarındaki ortalama
              sıralaması.
            </li>
          </ul>
          <p className="mt-2">
            {" "}
            Bu metrikler, sitenizin performansını değerlendirmek ve hangi
            alanlarda iyileştirmeler yapmanız gerektiğini belirlemek için
            önemlidir.
          </p>
        </div>

        <div className="mt-5">
          <h3 className="text-xl font-semibold mb-3">2. URL Denetleme Aracı</h3>
          <p>
            URL Denetleme Aracı, belirli bir sayfanın Google tarafından nasıl
            görüldüğünü kontrol etmenizi sağlar. Bu araç sayesinde:
          </p>
          <ul className="list-disc pl-4">
            <li>
              Sayfanın Google'da dizine eklenip eklenmediğini görebilirsiniz.{" "}
            </li>
            <li>Sayfanın mobil uyumluluğunu kontrol edebilirsiniz.</li>
            <li>
              Sayfanın herhangi bir dizine eklenme sorununu tespit
              edebilirsiniz.
            </li>
          </ul>
        </div>

        <div className="mt-5">
          <h3 className="text-xl font-semibold mb-3">3. Dizin Oluşturma</h3>
          <p>
            Bu bölüm, sitenizin kaç sayfasının Google tarafından dizine
            eklendiğini gösterir. Ayrıca, dizine eklenmeyen sayfaların neden
            dizine eklenmediğini belirlemenize yardımcı olur. Dizine eklenmeyen
            URL’leri manuel olarak ekliyebilirsiniz. Ayrıca Google’da
            gösterilmesini istemediğiniz URL’leri de aynı şekilde "URL Kaldırma"
            kısmından kaldırabilirsiniz. "Yeni İstek" buttonuna tıkladığınızda
            açılan modüle URL’yi girmeniz yeterli, talebinizin işlenmesi biraz
            zaman alabilir.
          </p>
          <img src={image4} alt="image" className="m-auto mt-5" />
        </div>

        <div className="mt-5">
          {" "}
          <h3 className="text-xl font-semibold mb-3">
            4. Sitemap (Site Haritası)
          </h3>
          <p>
            Sitemap, sitenizin yapısını ve içeriklerini Google'a bildirmek için
            kullanılan bir dosyadır. Google Search Console üzerinden sitemap
            dosyanızı gönderebilir ve durumunu takip edebilirsiniz. Bu,
            Google'ın sitenizi daha hızlı ve doğru bir şekilde dizine eklemesine
            yardımcı olur. Sitemap haritanızı nasıl oluşturabilirsiniz? Sitemap
            haritanızı oluşturmak için ücretsiz platformları kullanabileceğiniz
            gibi bunu manuel olarak da oluşturabilirsiniz. Örneğin{" "}
            <Link to="https://www.xml-sitemaps.com/">
              {" "}
              https://www.xml-sitemaps.com{" "}
            </Link>
            adresine giderek URL’mi ilgili kısma yapıştırdım.{" "}
          </p>
          <img src={image5} alt="image" className="m-auto mt-5" />
          <p className="mt-5">
            Bana sitemin haritası için hazırladığı .xml dosyasını indirme imkanı
            sağlıyor ve bu dosyayı alarak projemin public klasörüne atıyorum.
            Dosyanın içerisinde aşağıdaki gibi bir URL tagi göreceksiniz, burada
            sitenize ait hangi URL’lerin Google tarafından indexlenmesini
            istiyorsanız onları yazmanız gerekecek. Şuan sitemi geliştirirken
            kullandığım framework nedeniyle benim web siteme ait bütün pathleri
            algılamadı ve sadece anasayfamı indexledi, ama ben about, blog gibi
            diğer sayfalarımın da indexlenmesini isteyebilirim. O zaman aynı
            şekilde o adreslerinde URL tagleri içerisinde yer alması gerekiyor.
            Eğer sizde de bendeki gibi tüm sayfalarınız yoksa ve manuel olarak
            yazmak ile uğraşmak da istemiyorum diyorsanız sitemap oluşturmanıza
            yardımcı olan npm paketlerinden birisini kullanabilirsiniz. Sitemap
            haritasında ayrıca ne kadar sürede bir güncellemeleri indexlemesini
            istediğinizi de belirtebilirsiniz.{" "}
          </p>
          <img src={image6} alt="image" className="m-auto mt-5" />
          <p className="mt-5">
            Sitemap dosyanızı biraz daha profesyonel olarak hazırlamak ve
            incelemek istiyorsanız bunun için kullanabileceğiniz bazı yazılımlar
            da mevcut. Örneğin{" "}
            <Link to="https://www.screamingfrog.co.uk/seo-spider/">
              screamingfrog{" "}
            </Link>
            yazılımını kullanarak detaylı bir inceleme ve oluşturma
            yapabilirsiniz.
          </p>
          <img src={image7} alt="image" className="m-auto mt-5" />
          <p className="mt-5">
            Özetle sitemap.xml dosyamı hazırladım ve bunu yine public klasörüme
            attım. Şimdi haritanızın ismini web sitenizin devamına yazarak
            gönder buttonuna tıklamanız gerekiyor. İlk girdiğinizde hata
            verebiliyor, altta site haritanız eklenip başarılı yazısını görene
            kadar eklemeye devam edin. Ancak Google’ın indexleme yapmasının
            birkaç gün ya da hafta sürebileceğini unutmayın.
          </p>
          <img src={image8} alt="image" className="m-auto mt-5" />
        </div>

        <div className="mt-5">
          <h3 className="text-xl font-semibold mb-3">5. Sayfa Deneyimi</h3>
          <p>
            Google, mobil uyumluluğa ve sayfa deneyimine büyük önem verir. Sayfa
            Deneyimi raporu, sitenizin mobil cihazlarda nasıl göründüğünü ve
            kullanıcı deneyimini çeşitli sinyallere göre değerlendirir. Bu
            raporda sayfa deneyimi sorunlarını tespit edebilir ve
            düzeltebilirsiniz.
          </p>
        </div>
        <div className="mt-5">
          <h3 className="text-xl font-semibold mb-3">
            6. Güvenlik ve Manuel İşlemler
          </h3>
          <p>
            Google Search Console, sitenizin güvenliğini izler ve herhangi bir
            güvenlik sorunu tespit ederse sizi bilgilendirir. Ayrıca, manuel
            işlemler raporunda Google'ın sitenizle ilgili aldığı manuel
            işlemleri görebilir ve bu işlemleri düzeltmek için adımlar
            atabilirsiniz.
          </p>
        </div>
        <p className="mt-5">
          Google Search Console, web sitenizin arama motoru optimizasyonu (SEO)
          stratejilerini geliştirmenize yardımcı olan güçlü bir araçtır. Bu
          rehberde, temel özelliklerini ve nasıl kullanıldığını özetledim.
          Google Search Console'u düzenli olarak kullanarak, sitenizin
          performansını izleyebilir, sorunları tespit edip düzeltebilir ve arama
          sonuçlarındaki sıralamanızı iyileştirebilirsiniz. Unutmayın, başarılı
          bir SEO stratejisi için düzenli analiz ve iyileştirme şarttır.
        </p>
      </div>
    ),
  },
  {
    id: 6,
    content: (
      <div className="p-[1rem] sm:p-[5rem] ">
     
          <h1 className="text-2xl sm:text-center pb-5 pt-5 sm:pt-0 font-bold">
            İnsanlar Hikayelerini Acilen Anlatmalı
          </h1>{" "}
          <p className="pb-5">
            Ah Didem, insanlar sadece rüyalarını mı acilen anlatmalı, insanlar
            hikayelerini de acilen anlatmalı. “Belki ben de hepinizden farklı
            bir solucandım kim bilir” diyebilmeli insan Turgut gibi. "Şimdi
            yarısı ezilmiş, yerde yattığı için belli olmuyor..."
          </p>
          <p className="pb-5">
            Hikayelerimiz, biz onları anlatmadığımız sürece yanlış anlatılmaya
            mahkum. Savunma hakkı elinden alınmış, sadece yargılanmış. Oysa ben
            de her çocuk gibi hikayeme sahip çıkmak yerine kendi gölgemi
            kaybetmeyi hayal ettim. Bir gün ben de uyanacağım ve açık camdan Pan
            ile bilinmeyen ülkeye kaçacağım... Öyle pervasızca, ölçüp biçmeden,
            yarısı yerde kalmış bir hikaye bırakarak kaçıp gideceğim hem de...
          </p>
          <p className="pb-5">
            Öyle büyük bir literatür çöplüğü varki etrafta her tarafa bir hikaye
            saçılmış, solunda duran metruk yapı bağırıyor, sağında duran ağaç
            ağlıyor. Birbirinden kopuk kopuk derlenmemiş hikayeler...
            Güvenirliği sallantıda geçerliliği yok edilmiş duruyor orada. Sahibi
            tarafından anlatılmadıysa bir iz sürücüye ihtiyaç duymakta. Dinlemeyi
            bilen gerçeği cımbızla çeken...
          </p>
          <p className="pb-5">
            Ben hiç bilmiyordum mesela mimoza ağaçları da öğrenmeyi severmiş, sırtımı
            dayadığım ağaç benimle aynı kaygıya sahipmiş. En yalnız sokak
            çocukları karşı kaldırımdaki ağaçlarmış... Wohlleben ağaçların hikayesine sahip çıkmasa
            hiç bilmiyordum...
          </p>
          <p className="pb-5">
            Xing kendi hikayesine sahip çıkmasa nasıl bir ülke insana “Rüzgarda
            savrulan yaprak” metaforunu düşündürürdü bilmiyordum...
          </p>
          <p className="pb-12">
            Belki Randell’ın bir şehre bakması gibi ben de kendime bakınca bir
            hikaye görüyorum yakından bakıldığında tam bir karmaşayı andırıyor,
            uzaktan bakıldığında soğuk ve okunmamış. İnsanlar hikayelerini acilen anlatmalı...
          </p>
     

        <img src={city} alt="city" className="m-auto" />
      </div>
    ),
  },
];

export const literatureData = [
  {
    id: 1,
    bgColor: "bg-[#F7F7F7]",
    textColor: "text-black",
    content: "Boşluklu Yaşamak",
    isImage: false,
  },
  {
    id: 1,
    bgColor: "bg-[#F3EDE9]",
    textColor: "text-black",
    content: "01",
    isImage: false,
    fontSize: true,
  },
  {
    id: 1,
    bgColor: "bg-[#EFEFEF]",
    textColor: "",
    content: "",
    isImage: true,
    imageSrc: man,
  },
  {
    id: 2,
    bgColor: "bg-[#F3EDE9]",
    textColor: "text-black",
    content: "02",
    isImage: false,
    fontSize: true,
  },
  {
    id: 2,
    bgColor: "bg-[#E5E5E5]",
    textColor: "",
    content: "",
    isImage: true,
    imageSrc: bird,
  },
  {
    id: 2,
    bgColor: "bg-[#F7F7F7]",
    textColor: "text-black",
    content: "Kelimeler ve Şeyler",
    isImage: false,
  },

  {
    id: 3,
    bgColor: "bg-[#FFFFFF]",
    textColor: "",
    content: "",
    isImage: true,
    imageSrc: book,
  },
  {
    id: 3,
    bgColor: "bg-[#F7F7F7]",
    textColor: "text-black",
    content: "Kitap Kurtlarına",
    isImage: false,
  },
  {
    id: 3,
    bgColor: "bg-[#F3EDE9]",
    textColor: "text-black",
    content: "03",
    isImage: false,
    fontSize: true,
  },

  {
    id: 6,
    bgColor: "bg-[#F3EDE9]",
    textColor: "text-black",
    content: "04",
    isImage: false,
    fontSize: true,
  },
  {
    id: 6,
    bgColor: "bg-[#F7F7F7]",
    textColor: "",
    content: "",
    isImage: true,
    imageSrc: city,
  },
  {
    id: 6,
    bgColor: "bg-[#F7F7F7]",
    textColor: "text-black",
    content: "İnsanlar Hikayelerini Acilen Anlatmalı",
    isImage: false,
  },
];

export const technicalData = [
  {
    id: 4,
    bgColor: "bg-[#F7F7F7]",
    textColor: "text-black",
    content: "Adding Images in React",
    isImage: false,
  },
  {
    id: 4,
    bgColor: "bg-[#F3EDE9]",
    textColor: "text-black",
    content: "01",
    isImage: false,
    fontSize: true,
  },
  {
    id: 4,
    bgColor: "bg-[#EDEDED]",
    textColor: "",
    content: "",
    isImage: true,
    imageSrc: hansel,
  },

  {
    id: 5,
    bgColor: "bg-[#F3EDE9]",
    textColor: "text-black",
    content: "02",
    isImage: false,
    fontSize: true,
  },
  {
    id: 5,
    bgColor: "bg-[#F1F4F9]",
    textColor: "",
    content: "",
    isImage: true,
    imageSrc: google,
  },
  {
    id: 5,
    bgColor: "bg-[#F7F7F7]",
    textColor: "text-black",
    content: "Google Search Console Kullanımı",
    isImage: false,
  },

  // {
  //   id: 6,
  //   bgColor: "bg-[#EFEFEF]",
  //   textColor: "",
  //   content: "",
  //   // isImage: true,
  //   // imageSrc: tree,
  // },
  // {
  //   id: 6,
  //   bgColor: "bg-[#D5D5D5]",
  //   textColor: "text-black",
  //   content: "",
  //   isImage: false,
  // },
  // {
  //   id: 6,
  //   bgColor: "bg-[#F3EDE9]",
  //   textColor: "text-black",
  //   content: "03",
  //   isImage: false,
  //   fontSize: true,
  // },
];

export const projectData = [
  {
    title: "CactusInk Blog Site",
    liveSite: "https://cactusink-esma.netlify.app/",
    githubRepo: "https://github.com/esmaaksoy/CactusInk-Backend",
    description:
      "User registration and article contribution are enabled by this dynamic blog site. Drafts can be saved or articles can be published directly. Content management is facilitated by users accessing their profiles to perform CRUD operations on their articles. Published articles can be commented on and liked by users.",
    technicalSkills: [
      "Node.js",
      "Express.js",
      "MongoDB",
      "Mongoose",
      "React",
      "Redux",
      "Tailwind CSS",
    ],
    image: blog,
    icon: <PiCactusThin className="text-7xl m-auto text-[#AED1B2]" />,
    bgColor: "bg-[#F7F7F7]",
    imagePosition: "left",
  },
  {
    title: "Stock Management System",
    liveSite: "https://stock-management-system-esma.netlify.app/",
    githubRepo: "https://github.com/esmaaksoy/Stock-Management-System-Backend",
    description:
      " A stock management system application is presented in this project. Login and registration processes are provided to users. Firm, brand, and stock data can be managed by users with CRUD operations. Sorting and filtering operations on tabular data are supported by the application. The data can be visually seen by users with the help of provided graphics.",
    technicalSkills: [
      "Node.js",
      "Express.js",
      "MongoDB",
      "Mongoose",
      "React",
      "Redux",
      "Tailwind CSS",
    ],
    image: stock,
    icon: <PiChartLineUpThin className="text-7xl m-auto text-[#ABFB60]" />,
    bgColor: "bg-[#EDEDED]",
    imagePosition: "right",
  },
  {
    title: "Home Page",
    liveSite: "https://home-page-esma.netlify.app/home",
    githubRepo: "https://github.com/esmaaksoy/Home-backend",
    description:
      "This project is a web application that allows users to login and logout, view existing products, product details, perform searches, and send emails.",
    technicalSkills: [
      "Node.js",
      "Express.js",
      "MongoDB",
      "Mongoose",
      "React",
      "Context",
      "Tailwind CSS",
    ],
    image: home,
    icon: <PiBuildingsThin className="text-7xl m-auto" />,
    bgColor: "bg-[#F7F7F7]",
    imagePosition: "left",
  },
];

export const otherProject = [
  {
    image: movie,
    title: "Movie App",
    content:
      "This application is a movie streaming service that allows users to register, log in, and explore details of films. Additionally, users can log in with their Google accounts. Registered users can watch movies, view their details, and search for films. Firebase, Axios, react-toastNotify, and API requests were utilized in the project.",
    liveSite: "https://movie-app-esma.netlify.app/",
    githubRepo: "https://github.com/esmaaksoy/Movie-App",
  },
  {
    image: recipe,
    title: "Recipe App",
    content:
      "This application is a recipe website that provides access to detailed recipes for logged-in users. Users with a registered account can log in with their credentials, create a new account if they don't have one, or log in using their Google account.",
    liveSite: "https://recipe-app-esma.netlify.app/",
    githubRepo: "https://github.com/esmaaksoy/Recipe-App",
  },
  {
    image: things,
    title: "Things Shop",
    content:
      "In this application, you can access products only after logging in. Once logged in, you can navigate between pages, view product details, and perform searches related to products. To implement this scenario, I utilized API requests, the React-Router-Dom, and Context API in this application.",
    liveSite: "https://things-shop-esma.netlify.app/",
    githubRepo: "https://github.com/esmaaksoy/Things-Shop",
  },
  {
    image: bookStore,
    title: "Book Store",
    content:
      "This project incorporates technologies such as React, Bootstrap, SweetAlert2, and Axios. Additionally, CRUD (Create, Read, Update, Delete) operations are implemented in the project using a mock API. React Router is employed to enhance functionality and improve the user experience.",
    liveSite: "https://our-bookstore-esma.netlify.app/",
    githubRepo: "https://github.com/esmaaksoy/BookStore-Checkout",
  },

  {
    image: bookTracker,
    title: "Book Tracker",
    content:
      "This project is a Book Tracker application built with Express.js and Sequelize ORM. It allows users to perform CRUD (Create, Read, Update, Delete) operations on a collection of books. The application uses PostgreSQL as the database and is connected to the frontend using CORS.",
    liveSite: "https://book-tracker-frontend-five.vercel.app/",
    githubRepo: "https://github.com/esmaaksoy/bookTracker-frontend",
  },
  {
    image: wednesday,
    title: "Wednesday Addams",
    content:
      "In the application featuring the popular series Wednesday Addams, you can see a detailed use of React, SASS, and Bootstrap.",
    liveSite: "https://wednesday-esma.netlify.app//",
    githubRepo: "https://github.com/esmaaksoy/Wednesday",
  },
  {
    image: hogwarts,
    title: "Hogwarts",
    content:
      "In the application featuring the popular movie Harry Potter, you can see a detailed use of Bootstrap.",
    liveSite: "https://esmaaksoy.github.io/Hogwarts-Website/",
    githubRepo: "https://github.com/esmaaksoy/Hogwarts-Website",
  },
  {
    image: color,
    title: "Color Generator",
    content:
      "This application developed with React provides a tool to easily create color palettes and allows users to copy color codes. If the user makes an incorrect color input, a red border appears around the input.",
    liveSite: "https://color-generator-esma.netlify.app/",
    githubRepo: "https://github.com/esmaaksoy/Color-Generator",
  },
];

export const timeLineData = [
  {
    date: <img src={me} alt="me" className="lg:w-[60%]" />,
    icon: <PiSparkleThin />,
    content: (
      <div className="sm:p-12">
        After graduating from{" "}
        <span className="font-semibold">
          computer and instructional technologies education
        </span>
        , 3.5 years were spent working as a computer teacher. Expertise in{" "}
        <span className="font-semibold">
          web accessibility and usability on websites
        </span>{" "}
        was gained during master's studies. Then transitioned to the{" "}
        <span className="font-semibold">Fullstack Development</span> field.
      </div>
    ),
  },

  {
    date: (
      <div>
        Education
        <img
          src={graduated}
          alt="image"
          className="w-[50%] m-auto hidden lg:block"
        />
      </div>
    ),
    icon: <PiGraduationCapThin />,
    content: (
      <div className="sm:p-12 flex flex-col gap-3">
        <p>
          {" "}
          <span className="text-gray-500">Anadolu University (2018-2021)</span>
          <br />
          Sociology
        </p>
        <p>
          <span className="text-gray-500">
            {" "}
            Uludag University | Master (2015-2018)
          </span>{" "}
          <br />
          Computer and Instructional Technologies Education
        </p>
        <p>
          <span className="text-gray-500">Uludag University (2011-2015)</span>{" "}
          <br />{" "}
          <span className="font-bold">
            Computer and Instructional Technologies Education
          </span>
        </p>
      </div>
    ),
  },

  {
    date: (
      <div>
        Experience
        <img src={experience} alt="image" className="hidden lg:block" />
      </div>
    ),
    icon: <PiCodeThin />,
    content: (
      <div className="flex flex-col gap-[2rem]">
        <p>
          Computer Instructor | Sep 2016 - Jan 2018 <br />
          <span className="text-gray-500">
            Bursa Science and Technology Center (Bursa, TR)
          </span>
        </p>

        <p>
          {" "}
          Computer Instructor | Sep 2016 - Jan 2017 <br />
          <span className="text-gray-500">
            Bursa Metropolitan Municipality (Bursa, TR)
          </span>
        </p>

        <div className="flex items-center justify-between ">
          <p>
            Internship | Feb 2013 - Mar 2013 <br />
            <span className="text-gray-500">
              {" "}
              O2-Telefonica UK (Berlin,Germany){" "}
            </span>
          </p>

          <img src={germany} alt="image" className="w-[40%]" />
        </div>
      </div>
    ),
  },

  {
    date: (
      <div>
        Certification
        <img
          src={certification}
          alt="certification image"
          className="hidden lg:block"
        />
      </div>
    ),
    icon: <PiCertificateThin />,
    content: (
      <div>
        <p>
          Backend Development |{" "}
          <span className="text-gray-500">Clarusway IT School (2024)</span>
        </p>
        <p>
          Web Development With React |{" "}
          <span className="text-gray-500">Clarusway IT School (2024)</span>
        </p>
        <p>
          Web Development Essentials |{" "}
          <span className="text-gray-500">Clarusway IT School (2024)</span>
        </p>
        <p>
          Linux System Administration |{" "}
          <span className="text-gray-500">
            The Linux Users Association (2016)
          </span>
        </p>
        <p>
          Work Accidents |{" "}
          <span className="text-gray-500">
            European Business Academy (2015)
          </span>
        </p>
        <p>
          Advocacy and Campaign Management |{" "}
          <span className="text-gray-500">
            Turkish Democracy Foundation and Konrad Adanauer Stiftung
            Association (2015)
          </span>
        </p>
        <p>
          Increase of the Employment Opportunities for Females Through
          Information Security And E-Sign Internship Applications In EU
          Countries |
          <span className="text-gray-500">
            {" "}
            European Business Academy (2013)
          </span>
        </p>
      </div>
    ),
  },

  {
    date: <div>Publications</div>,
    icon: <PiTextAlignRightThin />,
    content: (
      <div className="flex flex-col gap-[2rem]">
        <Link to="https://dergipark.org.tr/tr/pub/uefad/issue/41838/505613">
          While Education is Immigrating to the Internet, Do We Leave Disabled
          Behind? Uludağ University{" "}
          <span className="font-bold">Accessibility </span>Evaluation{" "}
        </Link>
        <Link to="https://avesis.uludag.edu.tr/yayin/a8b0eba9-f44a-4563-ab31-bf45d884894a/uludag-universitesi-kurum-egitim-ve-arastirma-faaliyetleri-yonetisim-sistemi-ukey-kullanilabilirlikdegerlendirmesi">
          <span className="font-bold">Usability</span> Evaluation of Uludağ
          University Institutional, Educational, and Research Activities
          Governance System | Turkey
        </Link>

        <div>
          <Link to="https://www.researchgate.net/profile/Burcu-Durmaz/publication/312490182_The_Influence_of_the_History_of_Mathematics_on_Pre-Service_Elementary_School_Mathematics_Teachers%27_Mathematics_Teaching_Efficacy_Beliefs_and_Mathematics_Teaching_Anxiety_Levels/links/587e5baa08aed3826af45e32/The-Influence-of-the-History-of-Mathematics-on-Pre-Service-Elementary-School-Mathematics-Teachers-Mathematics-Teaching-Efficacy-Beliefs-and-Mathematics-Teaching-Anxiety-Levels.pdf">
            Crafting Lessons with Technology: A Practical Example for
            Student-Teachers | Finland
          </Link>
          <img src={finland} alt="finland" />
        </div>
      </div>
    ),
  },

  {
    date: <div>Languages</div>,
    icon: <PiTranslateThin />,
    content: (
      <div>
        <p>Turkish</p>
        <p>English</p>
        <p>
          Arabic <br />
          <span className="text-gray-500">
            Ankara Haci Bayram Veli University Arabic Translation and
            Interpretation(2022-2023)
          </span>
        </p>
        <p>
          Germany <br />{" "}
          <span className="text-gray-500">
            {" "}
            Leonardo da Vinci Project (Berlin / Germany){" "}
          </span>
        </p>
      </div>
    ),
  },

  {
    date: (
      <div>
        Hobbies
        <img src={me} alt="image" className="lg:mt-[8rem]" />
      </div>
    ),
    icon: <PiSunHorizonThin />,
    content: (
      <div className="flex flex-col gap-2">
        <div>
          {" "}
          <div className="flex gap-2  items-center  ">
            <p className="flex-1">Chess</p>
            <img src={chess} alt="chess image" className="flex-1 w-[20%]" />
            <p className="flex-1">
              Chess Arbiter <br /> Turkish Chess Federation
            </p>
          </div>
          <p>
            I have been playing chess as a licensed player since my childhood.
            After serving as the captain of my high school chess team, I pursued
            becoming a chess arbiter in university.
          </p>
        </div>

        <div>
          <div className="flex gap-2  items-center  ">
            <p className="flex-1">Literature</p>
            <img src={write} alt="write image" className="flex-1 w-[50%]" />
          </div>
          <p>
            I love literature, reading, and writing. I am currently working on
            writing a book.
          </p>
        </div>
        <div>
          {" "}
          <div className="flex gap-2 items-center  ">
            <p className="flex-1">Amigurumi</p>
            <img src={amigurimi} alt="amigurimi" className="w-[50%] flex-1" />
          </div>
          <p>
            I am interested in the Japanese art of crocheting called amigurumi,
            and I crochet my favorite fairy tale characters.
          </p>
        </div>
      </div>
    ),
  },
];
