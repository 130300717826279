import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Project from "./pages/Project";
import Contact from "./pages/Contact";
import ScrollToTop from "./pages/ScroolToTop";
import { ToastContainer } from "react-toastify";
import Blog from "./pages/Blog";
import BlogDetail from "./pages/BlogDetail";
import Literature from "./pages/Literature";
import Technical from "./pages/Technical";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

function App() {
  const location = useLocation();
  return (
    <>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Project />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/literature" element={<Literature />} />
        <Route path="/blog/technical" element={<Technical />} />
        <Route path="/detail/:id" element={<BlogDetail />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      {location.pathname !== '/' && <Footer />}
      <ToastContainer />
    </>
  );
}

export default App;
