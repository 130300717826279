import { Helmet } from "react-helmet";
import { literatureData } from "../helpers/Data";

import TechnicalCard from "../components/TechnicalCard";

const Literature = () => {
  return (
    <>
      <Helmet>
        <title>Blog | Esma Aksoy</title>
        <meta name="description" content="Your page description" />
      </Helmet>
      <div className="min-h-screen bg-white  grid grid-cols-3 p-1 gap-1 sm:gap-5 sm:p-12 font-sans">
        {literatureData.map((item, index) => (
          <TechnicalCard key={index} {...item} />
        ))}
      </div>{" "}
    </>
  );
};

export default Literature;
