import gsap from "gsap";
import { useEffect, useRef } from "react";
import bird from "../assets/birds.png";
import { Helmet } from "react-helmet";

const Home = () => {
  const titleRef = useRef();
  const headerRef = useRef();
  const textRef = useRef();
  const image = useRef();
  
  useEffect(() => {
    gsap.fromTo(
      titleRef.current,
      { x: 100, opacity: 0 },
      { x: 0, duration: 3, opacity: 1 }
    );
    gsap.fromTo(
      headerRef.current,
      { x: -100, opacity: 0 },
      { x: 0, duration: 3, opacity: 1 }
    );
    gsap.fromTo(
      textRef.current,
      { y: -100, opacity: 0 },
      { y: 0, duration: 3, opacity: 1 }
    );
    gsap.fromTo(
      image.current,
      { y: 100, x: 100, opacity: 0 },
      { y: 0, x: 0, duration: 3, opacity: 1 }
    );
  }, []);

  return (
    <>
      <Helmet>
        <title>Home | Esma Aksoy</title>
        <meta name="description" content="Your page description" />
      </Helmet>

      <div className="lg:flex lg:justify-between lg:w-[80%] lg:mx-auto h-screen lg:h-auto overflow-hidden">
        <div className="bg-black lg:w-1/2 h-1/2 text-white pt-10 sm:px-10 sm:pt-28 p-10 flex flex-col justify-center">
          <h1 className="text-xl font-bold md:pb-2 md:text-4xl" ref={headerRef}>
            Hi, I am Esma
          </h1>
          <h2 className="text-lg md:text-2xl font-bold md:pb-4" ref={titleRef}>
            And I am a FullStack Developer
          </h2>
          <h5 className=" md:text-2xl lg:text-lg" ref={textRef}>
            I am working on topics such as HTML, CSS, JS, React, MongoDB,
            Mongoose, Express.js, Node.js and responsive web design.
          </h5>
        </div>
        <div className="bg-white lg:w-1/2 h-1/2 p-5 pt-10">
          <img src={bird} alt="bird" className="w-full" ref={image} />
        </div>
      </div>
    </>
  );
};

export default Home;
