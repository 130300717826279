import { Link } from "react-router-dom";

const OtherProject = ({ image, liveSite,githubRepo, title, content }) => {
  return (
    <article className="overflow-hidden rounded-lg shadow transition hover:shadow-lg mt-4 mb-4 ">
      <img alt="Office" src={image} className="h-56 w-full" />

      <div className="bg-white p-4 sm:p-6">
        <div className="flex justify-between">
          <Link to={liveSite} className="block text-md text-gray-500">Live Site</Link>
          <Link to={githubRepo} className="block text-md text-gray-500">Github Repo</Link>
        </div>

        <h3 className="mt-0.5 text-lg text-gray-900">{title}</h3>
        <p className="mt-2 line-clamp-3 text-sm/relaxed text-gray-500">
          {content}
        </p>
      </div>
    </article>
  );
};

export default OtherProject;
