import { Link } from "react-router-dom";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef } from "react";
import gsap from "gsap";

const ProjectCard = ({
  image,
  icon,
  liveSite,
  githubRepo,
  description,
  technicalSkills,
  title,
  bgColor,
  imagePosition,
}) => {
  const titleRef = useRef();
  const iconRef = useRef();
  const fullStackRef = useRef();
  const descRef = useRef();
  const skillRef = useRef();
  const imgRef = useRef();

  gsap.registerPlugin(ScrollTrigger);
  useEffect(() => {
    gsap.fromTo(
      titleRef.current,
      { opacity: 0, x: -100 },
      {
        duration: 2,
        x: 0,
        opacity: 1,
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top bottom",
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      iconRef.current,
      { opacity: 0, x: 100 },
      {
        duration: 3,
        x: 0,

        opacity: 1,
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top bottom",
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      fullStackRef.current,
      { opacity: 0, x: -100 },
      {
        duration: 3,
        x: 0,
        opacity: 1,
        delay: 1,
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top bottom",
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      descRef.current,
      { opacity: 0, y: 100 },
      {
        duration: 3,
        y: 0,
        opacity: 1,
        delay: 2,
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top bottom",
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      skillRef.current,
      { opacity: 0, y: -100 },
      {
        duration: 3,
        y: 0,
        opacity: 1,
        delay: 2,
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top bottom",
          toggleActions: "play none none none",
        },
      }
    );
    gsap.fromTo(
      imgRef.current,
      { opacity: 0 },
      {
        duration: 5,
        opacity: 1,
        scrollTrigger: {
          trigger: titleRef.current,
          start: "top bottom",
          toggleActions: "play none none none",
        },
      }
    );
  }, []);
  return (
    <div
      className={`${
        imagePosition === "right" ? "lg:flex-row-reverse" : "lg:flex-row"
      } flex flex-col overflow-hidden`}
    >
      <Link  to={liveSite}
        className={`${bgColor} flex items-center justify-center p-5 border border-gray-400 flex-1`}
      >
        <img src={image} alt="image" ref={imgRef} />
      </Link>

      <div className={`${bgColor} border border-gray-400 p-5 flex-1`}>
        <p className="text-2xl sm:text-3xl p-5 text-center" ref={titleRef}>
          {title}{" "}
        </p>

        <div className="p-5" ref={iconRef}>
          {icon}

          <div className="flex gap-5 item-center justify-center font-bold text-xl">
            <Link to={liveSite}>Live Site </Link>
            <Link to={githubRepo}>Github Repo</Link>
          </div>
        </div>

        <div
          className="h-[3rem] sm:h-[5rem] text-xl text-center tracking-wider"
          ref={fullStackRef}
        >
          {" "}
          Fullstack Project
        </div>
        <div className="flex p-5 gap-5 flex-col sm:flex-row">
          <div className="flex-1 " ref={descRef}>
            {" "}
            <h2 className="text-2xl mb-3">Description</h2>
            <p>{description}</p>
          </div>

          <div className="flex-1 " ref={skillRef}>
            <ul className="text-2xl mb-3">Technical Skills</ul>
            {technicalSkills.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
