import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Navbar = ({ style }) => {
  const location = useLocation();
  const navbarClass = location.pathname === '/' ? 'navbar-home' : 'navbar-other';
  return (
    <nav className={navbarClass}>
      <div className="hidden md:block ml-10 text-lg md:text-3xl font-whisper">
        <p>Esma Aksoy</p>
        <p>Fullstack Developer</p>
      </div>
      <div className="flex gap-3 sm:gap-5 md:ml-auto sm:pl-10 md:pl-0 p-5 md:p-0 sm:text-xl  ">
        <NavLink to="/">Home</NavLink>
        {/* <div className="bg-white border-r-2 border-white"></div> */}
        <NavLink to="/about" >About</NavLink>
        <NavLink to="/projects">Projects</NavLink>
        <NavLink to="/blog">Blog</NavLink>
        <NavLink to="/contact">Contact</NavLink>
      </div>
    </nav>
  );
};

export default Navbar;
