import { timeLineData } from "../helpers/Data";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

const TimeLine = () => {
  return (
    <VerticalTimeline lineColor="#D5D5D5">
      {timeLineData.map(({ date, icon, content },index) => (
        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date={date}
          iconStyle={{ background: "#F7F7F7", color: "black" }}
          icon={icon}
          contentStyle={{ boxShadow: "none" }}
          key={index}
        >
          {content}
        </VerticalTimelineElement>
      ))}
    </VerticalTimeline>
  );
};

export default TimeLine;
