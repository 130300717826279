import React from "react";
import { technicalData } from "../helpers/Data";
import TechnicalCard from "../components/TechnicalCard";

const Technical = () => {
  return (
    <>
      <div className="bg-white font-sans">
        <div className="min-h-screen grid grid-cols-3 gap-1 p-1 sm:gap-5 sm:p-12">
          {technicalData.map((item, index) => (
            <TechnicalCard key={index} {...item} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Technical;
