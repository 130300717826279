import { Link } from "react-router-dom";
import { socialData } from "../helpers/Data";

const Footer = () => {
  return (
    <div className="bg-[#D5D5D5] p-5 sm:p-[2rem] flex md:justify-between text-[#F7F7F7] flex-col gap-3 items-center md:flex-row text-center">
      <h1 className="text-xl ">Copyright ©2023. All rights reserved.</h1>

      <div className="flex items-end gap-4 text-3xl">
        {socialData.map(({ icon, href }, index) => (
          <Link
            to={href}
            className="hover:text-black"
            key={index}
            target="_blank"
          >
            {icon}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Footer;
