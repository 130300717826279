import { Link } from "react-router-dom";

const TechnicalCard = ({
  bgColor,
  textColor,
  content,
  isImage,
  imageSrc,
  fontSize,
  id,
}) => {
  return (
    <Link to={`/detail/${id}`}
      className={`p-5 flex justify-center col-span-1 items-center ${bgColor}`}
    >
      
        {isImage ? (
          <img src={imageSrc} alt="content" className="sm:h-[300px] w-full object-contain" />
        ) : (
          <p className={`${fontSize ? "text-xl sm:text-5xl" : ""} ${textColor}`}>
            {content}
          </p>
        )}
      
    </Link>
  );
};

export default TechnicalCard;
